import React, { useEffect, useState, useRef, useCallback } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Form from 'react-bootstrap/Form';
import Accordion from "react-bootstrap/Accordion";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import { ApiService } from "../../Components/Services/apiservices";
import CartHeader from "../../Components/CartHeader";
import sessionCartData from "../../Components/Elements/cart_session_data";
import { Link, useNavigate, useLocation } from "react-router-dom";
import constant from "../../Components/Services/constant";
import multiCurrency from "../../Components/Elements/multi_currrency";
import numeral from "numeral";
import useRazorpay from "react-razorpay";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Collapse from 'react-bootstrap/Collapse';
import AddressModal from "../../Components/Modals/address_modal";

const stripePromise = loadStripe('pk_live_51POy4PIu2jePIzmTkEYks3FSzKMLEr6eb7LC4gWsai8DOF61BnVADayrzdEWN37cjSQmlZp0gMKW4oDqwikuLIrd00LrPmNLWu');

const PaymentForm = ({ clientSecret, isProcessing, setIsProcessing, onButtonClick, onPaymentSuccess }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [callPG, setcallPG] = useState(true);
    const didMountRef = useRef(true);
    const dataArray = sessionCartData();
    const parsedAddressSession = dataArray[0];
    const cardElementOptions = {
        style: {
            base: {
                fontSize: '16px',
                color: '#32325d',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
            },
        },
        hidePostalCode: true, // This will hide the postal code field
    };

    useEffect(() => {
        if (clientSecret) {
            if (didMountRef.current) {
                handlePaymentSubmit(clientSecret);
            }
            didMountRef.current = false;
        }
    },);

    const handlePaymentSubmit = async (clientSecret) => {
        if (!stripe || !elements) {
            return;
        }
        setIsProcessing(true);
        const cardElement = elements.getElement(CardElement);
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: parsedAddressSession.ua_name,
                },
            },
        });

        if (error) {
            console.log('[error]', error);
            setIsProcessing(false);
        } else {
            if (onPaymentSuccess) {
                onPaymentSuccess(paymentIntent);
            }
            setIsProcessing(false);
        }
    };

    const handleButtonClick = () => {
        if (typeof onButtonClick === 'function') {
            onButtonClick();
        }
    };


    return (
        <>
            <CardElement options={cardElementOptions} />
            <button onClick={handleButtonClick} className="btn btn-primary btn-block btn-large mt-3" disabled={isProcessing}>
                Proceed to Checkout
            </button>
        </>
    );
};

const CartCheckoutOld = () => {
    const didMountRef = useRef(true);
    const Navigate = useNavigate();
    const dataArray = sessionCartData();
    const parsedAddressSession = dataArray[0];

    const parsedCouponSession = dataArray[2];
    const [cartSummary, setcartSummary] = useState(dataArray[3]);
    const [parsedCartSession, setparsedCartSession] = useState(dataArray[1]);
    const [settingData, setSettingData] = useState({});
    const [shippingRateData, setShippingRateData] = useState({});
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState("credit-debit");
    const [paymentType, setPaymentType] = useState("1");
    const [shippingCharge, setShippingCharge] = useState(0);
    const [createorder, setCreateorder] = useState({});
    const [userData, setUserData] = useState("");
    const [Razorpay] = useRazorpay();
    const [shippingData, setShippingData] = useState({});
    const [clientSecret, setClientSecret] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [userAddressDetails, setUserAddressDetails] = useState({
        ua_id: 0,
        ua_name: "",
        ua_email: "",
        ua_mobile: "",
        ua_pincode: "",
        ua_house_no: "",
        ua_area: "",
        ua_state_name: "",
        ua_city_name: "",
    });



    const onTodoRegChange = (e) => {
        const { name, value } = e.target;

        setUserAddressDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };




    useEffect(() => {
        if (didMountRef.current) {
            //   getuserData()
            getSettingsData()
            if (localStorage.getItem("USER_TOKEN")) {
                cartSessionData();
            }
            //calculateShippingAmount(paymentType)
        }
        didMountRef.current = false;
    }, []);

    const cartSessionData = () => {
        const dataString = {
            coupon_session: localStorage.getItem("COUPON_SESSION"),
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
                setparsedCartSession(res.data.resCartData)
                setcartSummary(res.data.cartSummary)
            }
        });
    }

    const getSettingsData = () => {
        ApiService.fetchData("settings").then((res) => {
            if (res.status === "success") {
                setSettingData(res.sitesettings);
                setShippingRateData(res.shippingRateData);
                if (res.sitesettings.admin_cod_status === 1) {
                    // setPaymentMethod('COD')
                    calculateShippingAmount('1')
                } else {
                    // setPaymentMethod('credit-debit')
                    calculateShippingAmount('0')
                }
            }
        });
    };

    const calculateShippingAmount = (paymentType) => {
        const dataString = {
            itemtotal: cartSummary.total_amount,
            ua_id: parsedAddressSession.ua_id,
            cart_data: parsedCartSession,
            payment_type: paymentType,
            payment_type_admin: settingData.admin_cod_status
        };

        ApiService.postData("calculate-shipping-amount", dataString).then((res) => {
            if (res.status === "success") {
                setShippingCharge(res.shipping_amount);
                setShippingData(res.shipping_data)
                setSpinnerLoading(false);
            }
        });
    };
    const selectpaymentMode = (mode) => {
        setPaymentMethod(mode)
        if (mode === 'COD') {
            setPaymentType('1')
            setSpinnerLoading(true);
            calculateShippingAmount('1')
        } else {
            setPaymentType('0')
            setSpinnerLoading(true);
            calculateShippingAmount('0')
        }
    };

    const getuserData = () => {
        ApiService.fetchData("get-user-data").then((res) => {
            if (res.status == "success") {
                setUserData(res.rowUserData)
                setSpinnerLoading(false);
            } else {
                localStorage.removeItem("USER_TOKEN");
                setSpinnerLoading(false);
                Navigate("/");
            }
        });
    };

    const choutOutProccess = () => {
        if (paymentMethod === '') {
            toast.error('Please select Payment Method')
            return;
        }
        createOrderStripe();
        // if (paymentMethod !== 'COD') {
        //   if (settingData.admin_payment_active === 0) {
        //   } if (settingData.admin_payment_active === 1) {
        //   } if (settingData.admin_payment_active === 2) {
        //     createOrder()
        //   } else {

        //   }
        // } 

        // else {
        //   const dataString = {
        //     cartSummary: cartSummary,
        //     parsedAddressSession: parsedAddressSession,
        //     parsedCartSession: parsedCartSession,
        //     parsedCouponSession: parsedCouponSession,
        //     paymentMethod: paymentMethod,
        //     shippingCharge: parsedMultiCurrencySession?.cr_rate !== null ?shippingCharge / parsedMultiCurrencySession?.cr_rate:0,
        //     currencyData: parsedMultiCurrencySession,
        //     shippingData: shippingData,
        //     textarea: textarea
        //   };
        //   setSpinnerLoading(true)
        //   ApiService.postData("makecodorder", dataString).then((res) => {
        //     if (res.status === "success") {
        //       localStorage.removeItem("CART_SESSION")
        //       localStorage.removeItem("ADDRESS_SESSION")
        //       localStorage.removeItem("COUPON_SESSION")
        //       //localStorage.setItem("TRANS_ID", res.order_number);
        //       Navigate('/thankyou/' + res.order_number)
        //     } else {
        //       setSpinnerLoading(false)
        //     }
        //   });
        // }
    };


    const createOrderStripe = () => {
        const dataString = {
            amount: cartSummary.total_amount,
            paymentMethod: paymentMethod,
            shippingCharge: shippingCharge,
            couponAmount: parsedCouponSession.discount_amount,
            cartSummary: cartSummary,
            parsedAddressSession: parsedAddressSession,
            parsedCartSession: parsedCartSession,
            parsedCouponSession: parsedCouponSession,
            shippingData: shippingData,
            textarea: textarea
        };
        // setSpinnerLoading(true)
        ApiService.postData("createtemporder", dataString).then((res) => {
            if (res.status === "success") {
                console.log('dededede');
                setClientSecret(res.row_temp_trans.temp_clientsecret_key);
            } else {
                // setSpinnerLoading(false);
            }
        });
    };




    const handleButtonClick = () => {
        createOrderStripe();
    };
    const handlePaymentSuccess = (paymentIntent) => {
        stripeProcess(paymentIntent)
    };

    const stripeProcess = (response) => {
        console.log('stripeProcessAPI Call');
        const paymentData = {
            orderId: response.id,
            paymentId: response.payment_method,
            paymentresponse: response,
            cartSummary: cartSummary,
            parsedAddressSession: parsedAddressSession,
            parsedCartSession: parsedCartSession,
            parsedCouponSession: parsedCouponSession,
            paymentMethod: paymentMethod,
            shippingCharge: numeral(shippingCharge).format('0,0'),
            shippingData: shippingData,
            textarea: textarea
        };
        setSpinnerLoading(true)
        ApiService.postData("paymentprocessing", paymentData).then((result) => {
            if (result.status === "success") {
                setSpinnerLoading(false);
                Navigate('/thankyou/' + result.order_number)
            } else {
                setSpinnerLoading(false);
                toast.error("Payment processing failed. Please try again.");
            }
        });
    };

    const RazorPayProcess = (response, orderId) => {
        const MultiCurrencySession = localStorage.getItem("MULTI_CURRENCY");
        const parsedMultiCurrencySession = MultiCurrencySession ? JSON.parse(MultiCurrencySession) : {};
        const paymentData = {
            orderId: orderId,
            paymentId: response.razorpay_payment_id,
            cartSummary: cartSummary,
            parsedAddressSession: parsedAddressSession,
            parsedCartSession: parsedCartSession,
            parsedCouponSession: parsedCouponSession,
            paymentMethod: paymentMethod,
            shippingCharge: numeral(shippingCharge / parsedMultiCurrencySession.cr_rate).format('0,0'),
            currencyData: parsedMultiCurrencySession,
            shippingData: shippingData,
            textarea: textarea
        };
        setSpinnerLoading(true)
        ApiService.postData("paymentprocessing", paymentData).then((result) => {
            if (result.status === "success") {
                setSpinnerLoading(false);
                Navigate('/thankyou/' + result.order_number)
            } else {
                setSpinnerLoading(false);
                toast.error("Payment processing failed. Please try again.");
            }
        });
    };
    const [textarea, setTextarea] = useState('');

    const handleChangeTextarea = (event) => {
        setTextarea(event.target.value);
    };
    const [countryData, setCountryData] = useState([]);
    const [EditAddrData, setEditAddrData] = useState({});
    const [show, setShow] = useState(true);
    const handleShow = () => setShow(true);
    const handleChildData = (status) => {
        setShow(status);
    };

    useEffect(() => {
        setSpinnerLoading(true);
        if (didMountRef.current) {
            //   SetSession(localStorage.getItem("USER_TOKEN"));
            //   getUserAddress();
            getCountryData();
        }
        didMountRef.current = false;
    }, []);

    const getCountryData = () => {
        ApiService.fetchData("get-country").then((res) => {
            if (res.status == "success") {
                setCountryData(res.data);
            }
        });
    };

    return (
        <>
            <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                theme="light"
            />
            <CartHeader />
            {/* {spinnerLoading && <SpinnerLoader />} */}
            <BrowserView>
                <main>
                    <div className="page-content mt-5 mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div>

                                        <div>
                                            <h6>Contact</h6>
                                            <h6>Login</h6>
                                        </div>
                                        <div className='col-lg-12 col-12'>
                                            <div className="form-group-white">
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    name="ua_email"
                                                    className="form-control required"
                                                    onChange={(e) => { onTodoRegChange(e) }}
                                                />
                                            </div>

                                        </div>
                                        <div className='col-lg-6 col-6'>

                                            <div className="form-group-white">
                                                <input
                                                    type="checkbox"
                                                    name="ua_checkemail"
                                                    onChange={(e) => { onTodoRegChange(e) }}

                                                />
                                                <label>Email me with news and offers</label>
                                            </div>
                                        </div>

                                        <div>
                                            <h6>Delivery</h6>

                                        </div>
                                        <div className='row g-3'>
                                            <div className='col-lg-12 col-12'>
                                                <div className="form-group-white">
                                                    <label>Country</label>
                                                    <select name="ua_country_id" className="form-control required" disabled={true} value={13} onChange={(e) => { onTodoRegChange(e) }}>
                                                        <option value="">Select Country</option>
                                                        {countryData.map((value) => (
                                                            <option value={value.country_id}>{value.country_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-lg-12'>
                                                <div className="form-group-white">
                                                    <label>Full Name</label>
                                                    <input
                                                        type="text"
                                                        name="ua_name"
                                                        className="form-control required"
                                                        //   value={userAddressDetails.ua_name}
                                                        onChange={(e) => onTodoRegChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-12'>
                                                <div className="form-group-white">
                                                    <label>Address</label>
                                                    <input
                                                        type="text"
                                                        name="ua_address"
                                                        className="form-control required"
                                                        value={userAddressDetails.ua_name}
                                                        onChange={(e) => onTodoRegChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className='col-lg-6 col-6'>
              <div className="form-group-white">
                <label>House No, Building Name</label>
                <input
                  type="text"
                  name="ua_house_no"
                  className="form-control required"
                //   value={userAddressDetails.ua_house_no}
                //   onChange={(e) => onTodoRegChange(e)}

                />
              </div>
            </div> */}
                                            <div className='col-lg-4 col-4'>
                                                <div className="form-group-white">
                                                    <label>City</label>
                                                    <input
                                                        type="text"
                                                        name="ua_city_name"
                                                        className="form-control ua_city_name"
                                                        // value={userAddressDetails.ua_city_name}
                                                        onChange={(e) => onTodoRegChange(e)}
                                                        placeholder="City"
                                                    />

                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-4'>
                                                <div className="form-group-white">
                                                    <label>State</label>
                                                    <input
                                                        type="text"
                                                        name="ua_state_name"
                                                        className="form-control ua_state_name"
                                                        // value={userAddressDetails.ua_state_name}
                                                        onChange={(e) => onTodoRegChange(e)}
                                                        placeholder="State"
                                                    />

                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-4'>
                                                <div className="form-group-white">
                                                    <label>Postcode</label>
                                                    <input
                                                        type="number"
                                                        name="ua_pincode"
                                                        className="form-control required"
                                                        //   value={userAddressDetails.ua_pincode}
                                                        onChange={(e) => onTodoRegChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-12'>
                                                <div className="form-group-white">
                                                    <label>Mobile Number</label>
                                                    <input
                                                        type="number"
                                                        name="ua_mobile"
                                                        className="form-control required"
                                                        //   value={userAddressDetails.ua_mobile}
                                                        onChange={(e) => onTodoRegChange(e)}

                                                    />
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <Accordion defaultActiveKey={['2']} alwaysOpen>

                                        <Accordion.Item
                                            eventKey="2"
                                            className="checkout-accord"
                                        >
                                            <Accordion.Header>
                                                <h6 className="mb-0 tx-14">Payment Method</h6>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="payment-option-list">
                                                    <Elements stripe={stripePromise}>
                                                        <PaymentForm clientSecret={clientSecret} isProcessing={isProcessing} setIsProcessing={setIsProcessing} onButtonClick={handleButtonClick} StripeHandler={stripeProcess} onPaymentSuccess={handlePaymentSuccess}
                                                        />
                                                    </Elements>
                                                    <ul>
                                                        {/* {settingData.admin_cod_status === 1 ?
                              <li onClick={(e) => selectpaymentMode('COD')}>
                                <img src="/img/delivery.png" />
                                Cash On Delivery
                                <Form.Check
                                  className="ml-auto"
                                  name="payment_method"
                                  type="radio"
                                  value="COD"
                                  checked={paymentMethod === 'COD'}
                                // onChange={(e)=>selectpaymentMode('COD')}
                                />
                              </li>
                              : null} */}
                                                        {/* <li onClick={(e) => selectpaymentMode('credit-debit')}
                            >
                              <img src="/img/creditcard.png" />
                              Credit/Debit Card
                              <Form.Check
                                className="ml-auto"
                                name="payment_method"
                                type="radio"
                                value="credit-debit"
                                checked={paymentMethod === 'credit-debit'}
                              // onChange={(e)=>selectpaymentMode('credit-debit')}
                              />
                            </li>
                            <li onClick={(e) => selectpaymentMode('upi')}>
                              <img src="/img/phonepe.png" />
                              PhonePe/Gogle Pay/BHIM UPI
                              <Form.Check
                                className="ml-auto"
                                name="payment_method"
                                type="radio"
                                value="upi"
                                checked={paymentMethod === 'upi'}
                              // onChange={(e)=>selectpaymentMode('upi')}
                              />
                            </li>
                            <li onClick={(e) => selectpaymentMode('wallet')}>
                              <img src="/img/paytm.png" />
                              Paytm/Payzapp/Wallets
                              <Form.Check
                                className="ml-auto"
                                name="payment_method"
                                type="radio"
                                value="wallet"
                                checked={paymentMethod === 'wallet'}
                                onChange={(e) => selectpaymentMode('wallet')}
                              />
                            </li>
                            <li onClick={(e) => selectpaymentMode('netbanking')}>
                              <img src="/img/netbanking.png" />
                              Netbanking
                              <Form.Check
                                className="ml-auto"
                                name="payment_method"
                                type="radio"
                                value="netbanking"
                                checked={paymentMethod === 'netbanking'}
                              // onChange={(e)=>selectpaymentMode('netbanking')}
                              />
                            </li> */}
                                                    </ul>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="col-lg-4">
                                    <Accordion defaultActiveKey={['0', '2']} alwaysOpen>
                                        <Accordion.Item eventKey="0" className="checkout-accord">
                                            <Accordion.Header>
                                                <h6 className="mb-0 tx-14">Order Summary</h6>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {parsedCartSession.map((value, index) => {
                                                    return (
                                                        <div className="cartsec mt-0" key={index}>
                                                            <div className="row g-3">
                                                                <div className="col-lg-2 col-3">
                                                                    <div className="cartsec-media">
                                                                        <img src={value.product_image} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-9 col-9">
                                                                    <h6 className="cartsec-name">
                                                                        <a href="#" className="mycartbox-title">
                                                                            {value.product_name}
                                                                        </a>
                                                                    </h6>
                                                                    <div className="cartsec-price">
                                                                        <div className="price-new me-2">

                                                                            {multiCurrency(
                                                                                value.product_selling_price
                                                                            )}
                                                                        </div>
                                                                        {value.product_discount > 0 ?
                                                                            <div className="price-old">

                                                                                {multiCurrency(value.product_price)}
                                                                            </div> : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1" className="checkout-accord">
                                            <Accordion.Header>
                                                <h6 className="mb-0 tx-14">Order Note</h6>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="order-notetextarea">
                                                    <textarea name="textarea" className="form-control" placeholder="How can we help you?" value={textarea}
                                                        onChange={handleChangeTextarea} />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>
                                    <div className="panel p-4 mb-3">
                                        <div className="panel-header">Price Details</div>
                                        <div className="pcb-list mb-4">
                                            <ul>
                                                <li>
                                                    Item Total
                                                    <span className="ml-auto">
                                                        {multiCurrency(cartSummary.itemTotal)}
                                                    </span>
                                                </li>
                                                <li>
                                                    Discount
                                                    <span className="ml-auto tx-green">
                                                        -{multiCurrency(cartSummary.discount)}
                                                    </span>
                                                </li>
                                                <li>
                                                    Coupon Discount
                                                    <span className="ml-auto tx-green">
                                                        -
                                                        {multiCurrency(parsedCouponSession.discount_amount)}
                                                    </span>
                                                </li>
                                                <li>
                                                    Shipping Charge
                                                    <span className="ml-auto" id="shippingAmount">
                                                        {multiCurrency(shippingCharge)}
                                                    </span>
                                                </li>
                                                {settingData.estimated_delivery !== null && (
                                                    <li>
                                                        Estimated Delivery Time:
                                                        <span style={{ marginLeft: '10px' }}>
                                                            {settingData.estimated_delivery} {settingData.estimated_delivery === 1 ? 'day' : 'days'}
                                                        </span>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className="pcb-list-second">
                                            <ul>
                                                <li>
                                                    Total Amount
                                                    <span className="ml-auto" id="finalTotal">
                                                        {multiCurrency(cartSummary.total_amount + shippingCharge - parsedCouponSession.discount_amount)}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <hr />
                                        <p className="text-center mt-20">
                                            We Accepted all Major Cards
                                        </p>
                                        <div className="cardlist">
                                            <i className="fab fa-cc-paypal"></i>
                                            <i className="fab fa-cc-mastercard"></i>
                                            <i className="fab fa-cc-discover"></i>
                                            <i className="fab fa-cc-visa"></i>
                                        </div>
                                    </div>
                                    {/* <a
                    href="javascript:void(0);"
                    className="btn btn-primary btn-block btn-large"
                    onClick={choutOutProccess}
                  // onClick={handlePayment
                  >
                    Proceed to Checkout
                  </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <Footer />
            </BrowserView>
            <MobileView>
                <div className="address-checkout">
                    <a
                        href="/address"
                        className="change-address"
                    >
                        Change
                    </a>
                    <h6>Shipping and Billing Address</h6>
                    <p style={{ marginBottom: "3px", fontWeight: "600" }}>
                        {parsedAddressSession.ua_name} (
                        {parsedAddressSession.ua_address_type == "Other"
                            ? parsedAddressSession.ua_address_type_other
                            : parsedAddressSession.ua_address_type}
                        )
                    </p>
                    <p className="address-full" style={{ marginBottom: "3px" }}>
                        Mobile No: {parsedAddressSession.ua_mobile}
                    </p>
                    <div className="address-full">
                        {parsedAddressSession.ua_house_no}, {parsedAddressSession.ua_area},
                        {parsedAddressSession.ua_city_name}, {parsedAddressSession.ua_state_name}
                        {parsedAddressSession.ua_pincode}
                    </div>
                </div>
                <Accordion defaultActiveKey={['2']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="checkout-accord">
                        <Accordion.Header>

                            <h6 className="mb-0 tx-14">Order Summary</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            {parsedCartSession.map((value, index) => {
                                return (
                                    <div className="cartsec mt-0" key={index}>
                                        <div className="row g-3">
                                            <div className="col-lg-2 col-3">
                                                <div className="cartsec-media">
                                                    <img src={value.product_image} />
                                                </div>
                                            </div>
                                            <div className="col-lg-9 col-9">
                                                <h6 className="cartsec-name">
                                                    <a href="#" className="mycartbox-title">
                                                        {value.product_name}
                                                    </a>
                                                </h6>
                                                <div className="cartsec-price">
                                                    <div className="price-new me-2">

                                                        {multiCurrency(value.product_selling_price)}
                                                    </div>
                                                    <div className="price-old">
                                                        {multiCurrency(value.product_price)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="checkout-accord">
                        <Accordion.Header>
                            <h6 className="mb-0 tx-14">Order Note</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="order-notetextarea">
                                <textarea name="textarea" className="form-control" placeholder="How can we help you?" value={textarea}
                                    onChange={handleChangeTextarea} />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="checkout-accord">
                        <Accordion.Header>
                            <h6 className="mb-0 tx-14">Payment Method</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="payment-option-list">

                                <ul>
                                    {/* {settingData.admin_cod_status === 1 ?
                    <li onClick={(e) => selectpaymentMode('COD')}>
                      <img src="/img/delivery.png" />
                      Cash On Delivery
                      <Form.Check
                        className="ml-auto"
                        name="payment_method"
                        type="radio"
                        value="COD"
                        checked={paymentMethod === 'COD'}
                      // onChange={(e)=>selectpaymentMode('COD')}
                      />
                    </li>
                    : null} */}
                                    {/* <li onClick={(e) => selectpaymentMode('credit-debit')}
                  >
                    <img src="/img/creditcard.png" />
                    Credit/Debit Card
                    <Form.Check
                      className="ml-auto"
                      name="payment_method"
                      type="radio"
                      value="credit-debit"
                      checked={paymentMethod === 'credit-debit'}
                    // onChange={(e)=>selectpaymentMode('credit-debit')}
                    />
                  </li>
                  <li onClick={(e) => selectpaymentMode('upi')}>
                    <img src="/img/phonepe.png" />
                    PhonePe/Gogle Pay/BHIM UPI
                    <Form.Check
                      className="ml-auto"
                      name="payment_method"
                      type="radio"
                      value="upi"
                      checked={paymentMethod === 'upi'}
                    // onChange={(e)=>selectpaymentMode('upi')}
                    />
                  </li>
                  <li onClick={(e) => selectpaymentMode('wallet')}>
                    <img src="/img/paytm.png" />
                    Paytm/Payzapp/Wallets
                    <Form.Check
                      className="ml-auto"
                      name="payment_method"
                      type="radio"
                      value="wallet"
                      checked={paymentMethod === 'wallet'}
                      onChange={(e) => selectpaymentMode('wallet')}
                    />
                  </li>
                  <li onClick={(e) => selectpaymentMode('netbanking')}>
                    <img src="/img/netbanking.png" />
                    Netbanking
                    <Form.Check
                      className="ml-auto"
                      name="payment_method"
                      type="radio"
                      value="netbanking"
                      checked={paymentMethod === 'netbanking'}
                    // onChange={(e)=>selectpaymentMode('netbanking')}
                    />
                  </li> */}
                                </ul>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <div className="panel checkoutlist">
                    <div className="panel-header">Price Details</div>
                    <div className="pcb-list mb-4">
                        <ul>
                            <li>
                                Item Total
                                <span className="ml-auto">
                                    {multiCurrency(cartSummary.itemTotal)}
                                </span>
                            </li>
                            <li>
                                Discount
                                <span className="ml-auto tx-green">
                                    -{multiCurrency(cartSummary.discount)}
                                </span>
                            </li>
                            <li>
                                Coupon Discount
                                <span className="ml-auto tx-green">
                                    -{multiCurrency(parsedCouponSession.discount_amount)}
                                </span>
                            </li>
                            <li>
                                Shipping Charge
                                <span className="ml-auto" id="shippingAmount">
                                    {multiCurrency(shippingCharge)}
                                </span>
                            </li>
                            {settingData.estimated_delivery !== null && (
                                <li>
                                    Estimated Delivery Time:
                                    <span style={{ marginLeft: '10px' }}>
                                        {settingData.estimated_delivery} {settingData.estimated_delivery === 1 ? 'day' : 'days'}
                                    </span>
                                </li>
                            )}
                        </ul>
                    </div>
                    <hr />
                    <div className="pcb-list-second">
                        <ul>
                            <li>
                                Total Amount
                                <span className="ml-auto" id="finalTotal">
                                    {multiCurrency(cartSummary.total_amount + shippingCharge - parsedCouponSession.discount_amount)}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <hr />
                    <p className="text-center mt-20">We Accepted all Major Cards</p>
                    <div className="cardlist">
                        <i className="fab fa-cc-paypal"></i>
                        <i className="fab fa-cc-mastercard"></i>
                        <i className="fab fa-cc-discover"></i>
                        <i className="fab fa-cc-visa"></i>
                    </div>
                </div>
                <div className="footer-checkout">
                    <a
                        href="javascript:void(0);"
                        className="btn btn-primary btn-block btn-large"
                        onClick={choutOutProccess}
                    >
                        Proceed to Checkout
                    </a>
                </div>
            </MobileView>
        </>
    );
}
export default CartCheckoutOld;
