import React, { useEffect, useState, useRef, useContext } from "react";
import DataContext from "../Elements/eventContext";
import { ApiService } from "../Services/apiservices";
import Loader from "react-js-loader";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

function CheckoutAddressModal() {
    const didMountRef = useRef(true);
    const contextValues = useContext(DataContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [showCityState, setShowCityState] = useState(false);
    const [spinnerLoading, setspinnerLoading] = useState(false);
    const [statesData, setstatesData] = useState([]);
    const [cityData, setcityData] = useState([]);

    const [userAddressDetails, setUserAddressDetails] = useState({
        ua_id: 0,
        ua_name: "",
        ua_email: "",
        ua_mobile: "",
        ua_pincode: "",
        ua_house_no: "",
        ua_area: "",
        ua_state_name: "",
        ua_city_name: "",
        ua_address_type: "",
        ua_address_type_other: "",
        ua_state_id: "",
        ua_city_id: "",
        ua_default_address: "",
        ua_country_id: "101",
        ua_company_name: "",
        ua_gstin: "",
    });
    useEffect(() => {
        if (didMountRef.current) {
        }
        didMountRef.current = false;
    }, []);

    const toggleCheckoutAddressModal = () => {
        contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
    }

    const onTodoRegChange = (e) => {
        const { name, value } = e.target;
        setUserAddressDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (name === 'ua_state_id') {
            getcityData(value);
        }

        if (name === 'ua_pincode') {
            getStateData();
            if (value.length === 6) {
                checkPincode(value)
            } else {
                setShowCityState(true)
                setUserAddressDetails(prevState => ({
                    ...prevState,
                    ua_state_id: "",
                    ua_city_id: "",
                    ua_state_name: "",
                    ua_city_name: "",
                }));
            }
        }
    };

    const getStateData = () => {
        ApiService.fetchData("get-states-data").then((res) => {
            if (res.status === "success") {
                setstatesData(res.data);
            }
        });
    };

    const getallcityData = () => {
        ApiService.fetchData("getallcitydata").then((res) => {
            if (res) {
                setcityData(res);
            }
        });
    };

    const getcityData = (value) => {
        const dataString = {
            stateid: value
        }
        ApiService.postData("get-city-data", dataString).then(
            (res) => {
                if (res.status == "success") {
                    setcityData(res.data)
                }
            }
        );
    }

    const checkPincode = (value) => {
        const dataString = {
            pincode: value
        }
        setspinnerLoading(true);
        ApiService.postData("check-pincode", dataString).then(
            (res) => {
                if (res.status == "success") {
                    setShowCityState(true)
                    setUserAddressDetails(prevState => ({
                        ...prevState,
                        ua_state_id: res.data.pin_state_id,
                        ua_city_id: res.data.pin_city_id,
                        ua_state_name: res.data.State,
                        ua_city_name: res.data.City,
                    }));
                    setspinnerLoading(false);
                } else {
                    setShowCityState(false)
                    setUserAddressDetails(prevState => ({
                        ...prevState,
                        ua_state_id: "",
                        ua_city_id: "",
                        ua_state_name: "",
                        ua_city_name: "",
                    }));
                    setspinnerLoading(false);
                }
            }
        );
    }

    const handleAddressProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("required");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === "") {
                myElements[i].style.border = "1px solid red";
                counter++;
            } else {
                myElements[i].style.border = "";
            }
        }
        if (counter === 0) {
            if (userAddressDetails.ua_state_id == "" && userAddressDetails.ua_country_id == "101") {
                toast.success('Plese Select State'); 
                return false;
            } else if (userAddressDetails.ua_state_name == "" && userAddressDetails.ua_country_id != "101") {
                toast.success('Plese Enter State');
                return false;
            } 
            if (userAddressDetails.ua_city_id == "" && userAddressDetails.ua_country_id == "101") {
                toast.success('Plese Select City');
                return false;
            } else if (userAddressDetails.ua_city_name == "" && userAddressDetails.ua_country_id != "101") {
                toast.success('Plese Enter City');
                return false;
            } 
            setspinnerLoading(true);
            ApiService.postData("user-address-process", userAddressDetails).then(
                (res) => {
                    if (res.status == "success") {
                        contextValues.setUserAddressList(res.resAddressData);
                        setTimeout(() => {
                            setspinnerLoading(false);
                            contextValues.setDefaultAddressStatus(true)
                            contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
                        }, 500);
                    } else {
                        toast.error(res.message);
                        setspinnerLoading(false);
                    }
                }
            );
        }
    };

    return (
        <>
        <Modal show={contextValues.toggleCheckoutAddressModal} 
        // onHide={(e) => checkoutModal()} 
         className="checkoutModal" backdrop="static">
            <div className="AddressModal">
                <div className="AddressModalHeader">
                    <h6 className="mb-0">Add New Address</h6>
                    <button type="button" className="btnClose" onClick={(e) => toggleCheckoutAddressModal()}><i class="ri-close-line ri-2x"></i></button>
                </div>
                {spinnerLoading && <div className="pageLoder"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={50} /></div>}
                <div className="AddressModalContent">
                    <div className="row g-3 mb-20">
                        {/* <div className="col-lg-12"><div class="addTitle"><span>Personal Details</span></div></div> */}
                        <div className="col-lg-12">
                            <div className="flotting-group mb-10">
                            <label className="label">Name</label>
                            <input type="text" name="ua_name" className="required floating-input" value={userAddressDetails.ua_name} onChange={(e) => onTodoRegChange(e)} placeholder=""></input>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="flotting-group mb-10">
                            <label className="label">Mobile Number</label>
                                <input type="number" name="ua_mobile" className="required floating-input" value={userAddressDetails.ua_mobile} onChange={(e) => onTodoRegChange(e)} placeholder=""></input>
                           </div>
                        </div>
                        {/* <div className="col-lg-12"><div class="addTitle"><span>Address Details</span></div></div> */}
                        <div className="col-lg-12">
                            <div className="flotting-group mb-10">
                            <label className="label">House No, Building Name</label>
                                <input type="text" name="ua_house_no" className="required floating-input" value={userAddressDetails.ua_house_no} onChange={(e) => onTodoRegChange(e)} placeholder=""></input>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="flotting-group mb-10">
                            <label className="label">Road Name, Area, Colony</label>

                                <input type="text" name="ua_area" className="required floating-input" value={userAddressDetails.ua_area} onChange={(e) => onTodoRegChange(e)} placeholder=""></input>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="flotting-group mb-10">
                            <label className="label">Postcode</label>
                                <input type="number" name="ua_pincode" className="required floating-input" value={userAddressDetails.ua_pincode} onChange={(e) => onTodoRegChange(e)} placeholder=""></input>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="flotting-group mb-10">
                            <label className="label">State</label>
                                {showCityState ?
                                    <input type="text" className="floating-input" name="ua_state_name" value={userAddressDetails.ua_state_name} onChange={(e) => onTodoRegChange(e)} placeholder="" disabled="true" />
                                    :
                                    <select name="ua_state_id" className="form-select ua_state_id" value={userAddressDetails.ua_state_id} onChange={(e) => onTodoRegChange(e)}>
                                        <option value="">Select State</option>
                                        {statesData.map((value) => (
                                            <option value={value.state_id}>{value.state_name}</option>
                                        ))}
                                    </select>
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="flotting-group mb-10">
                            <label className="label">City</label>
                                {showCityState ?
                                    <input type="text" className="floating-input" name="ua_city_name" value={userAddressDetails.ua_city_name} onChange={(e) => onTodoRegChange(e)} placeholder="" disabled="true" />
                                    :
                                    <select name="ua_city_id" className="form-select ua_city_id" value={userAddressDetails.ua_city_id} onChange={(e) => onTodoRegChange(e)}>
                                        <option value="">Select City</option>
                                        {cityData.map((value) => (
                                            <option value={value.cities_id}>{value.cities_name}</option>
                                        ))}
                                    </select>
                                }
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="flotting-group mb-10">
                            <label className="label">Address Type</label>
                                <select name="ua_address_type" className="form-select required" value={userAddressDetails.ua_address_type} onChange={(e) => onTodoRegChange(e)}>
                                    <option value="">Select Address Type</option>
                                    <option value="Home">Home</option>
                                    <option value="Work">Work</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                        {userAddressDetails.ua_address_type === 'Other' &&
                            <div className="col-lg-12">
                                <div className="flotting-group mb-10">
                                    <input type="text" name="ua_address_type_other" className="required floating-input" value={userAddressDetails.ua_address_type_other} onChange={(e) => onTodoRegChange(e)} placeholder="Other"></input>
                                </div>
                            </div>
                        } 
                        <div className="col-lg-12">
                            <button type="button" className="btn btn-primary btn-block btn-large btn-checkoo mb-15" onClick={(e) => handleAddressProcess()}>Add Address</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>

        </>
    );
}
export default CheckoutAddressModal;
