import React, { useEffect, useState, useRef, useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import { ApiService } from "../../Components/Services/apiservices";
import AddressModal from "../../Components/Modals/address_modal";
import { useNavigate } from "react-router-dom";
import CartHeader from "../../Components/CartHeader";
import DataContext from "../../Components/Elements/eventContext";
import sessionCartData from "../../Components/Elements/cart_session_data";
import multiCurrency from "../../Components/Elements/multi_currrency";
import { validEmail } from "../../Components/Elements/Regex";
import Loader from "react-js-loader";
import CheckoutAddressModal from "../../Components/Modals/checkout_address_modal";
function CartAddress() {
    const didMountRef = useRef(true);
    const navigate = useNavigate();
    const dataArray = sessionCartData();
    const sessionData = sessionCartData();
    const contextValues = useContext(DataContext)
    const cartSummary = sessionData[3];
    const [defaultAddressStatus, setDefaultAddressStatus] = useState(false);

    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [hideShowLogin, setHideShowLogin] = useState(false);
    const [loginSpinnerLoading, setLoginSpinnerLoading] = useState(false);
    const [setSession, SetSession] = useState("");
    const [resUserAddress, setResUserAddress] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [EditAddrData, setEditAddrData] = useState({});
    const [rowUserData, setRowUserData] = useState({});
    const [userLoginDetails, setUserLoginDetails] = useState({
        user_email: "",
        user_password: "",
        session_data: dataArray[1],
    });

    const [emailFocused, setEmailFocused] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);


    useEffect(() => {
        setSpinnerLoading(true);
        if (didMountRef.current) {
            console.log('contextValues',contextValues);
            contextValues.setAddressSession(sessionData[0])
            contextValues.setCouponSession(sessionData[2])
            contextValues.setCartSummary(sessionData[3])
            if (localStorage.getItem("USER_TOKEN")) {
                contextValues.setUserToken(localStorage.getItem("USER_TOKEN"))
                cartSessionDataFetch();
            } else {
                contextValues.setCartCount(sessionData[1].length)
                contextValues.setCartSessionData(sessionData[1])
            }

            if (localStorage.getItem("USER_TOKEN")) {
                getUserData()
                getUserAddress();
            }
            getCountryData();
        }
        didMountRef.current = false;
    }, [contextValues]);

    const cartSessionDataFetch = () => {
        const dataString = {
          product_id: '',
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
          if (res.data.status === "success") {
            contextValues.setCartSessionData(res.data.resCartData)
            contextValues.setCartCount(res.data.resCartData.length)
            contextValues.setCartSummary(res.data.cartSummary)
          }
        });
      }

    const getUserData = () => {
        ApiService.fetchData("get-user-data").then((res) => {
            if (res.status == "success") {
                setRowUserData(res.rowUserData);
            }
        });
    };

    const getUserAddress = () => {
        ApiService.fetchData("get-user-address").then((res) => {
            if (res.status === "success") {
                if (res.resAddressDefault && res.resAddressDefault.ua_id > 0) {
                    contextValues.setDefaultAddressStatus(true)
                } else {
                    contextValues.setDefaultAddressStatus(false)
                }
                if (res.resUserAddress.length === 0) {
                }
                contextValues.setUserAddressList(res.resUserAddress);
                setResUserAddress(res.resUserAddress);
                setSpinnerLoading(false);
            } else {
                localStorage.removeItem("USER_TOKEN");
                setSpinnerLoading(false);
                navigate("/");
            }
        });
    };
    const getCountryData = () => {
        ApiService.fetchData("get-country").then((res) => {
            if (res.status == "success") {
                setCountryData(res.data);
            }
        });
    };

    const checkAvailibility = (addressData) => {
        setSpinnerLoading(true);
        ApiService.fetchData("get-user-address", addressData).then((res) => {
            if (res.status === "success") {
                localStorage.removeItem("ADDRESS_SESSION");
                setTimeout(() => {
                    localStorage.setItem("ADDRESS_SESSION", JSON.stringify(addressData));
                    navigate("/checkout");
                }, 1000);
            } else {
                setSpinnerLoading(false);
                toast.error(res.notification);
            }
        });

    };

    const showLogin = () => {
        setHideShowLogin(!hideShowLogin)
    }

    const logoutUser = () => {
        ApiService.fetchData("logout-user").then((res) => {
            window.location.reload();
        });
    }

    const onTodoChange = (e) => {
        const { name, value } = e.target;
        setUserLoginDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const userLoginProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("loginRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }

        if (counter === 0) {
            if (!validEmail.test(userLoginDetails.user_email)) {
                toast.error("Please enter valid Email Id");
                return false;
            }
            setLoginSpinnerLoading(true)
            ApiService.postData('user-login-process', userLoginDetails).then((res) => {
                if (res.status === "success") {
                    localStorage.setItem("USER_TOKEN", res.user_token)
                    toast.success(res.message)
                    setLoginSpinnerLoading(false)
                    localStorage.removeItem("COUPON_SESSION");
                    localStorage.removeItem("CART_SESSION");
                    window.location.reload();
                } else {
                    toast.error(res.message)
                    setLoginSpinnerLoading(false)
                }
            });
        }
    }; 
    const selectAddress = (addressValue) => {
        const productData = {
            ua_id: addressValue.ua_id,
        };
        ApiService.postData("chooseAddress", productData).then((res) => {
            if (res.status === "success") {
                setDefaultAddressStatus(true)
                contextValues.setUserAddressList(res.resUserAddress);
                setTimeout(() => {
                    setSpinnerLoading(false)
                }, 500);
            } else {
                setTimeout(() => {
                    setSpinnerLoading(false)
                }, 500);
            }
        }).catch((error) => {

        });
    }

    const toggleCheckoutAddressModal = () => {
        contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
    }

    const [promoCode, setPromoCode] = useState({ promo_code: "", });
    const onTodoChangePromo = (e) => {
      const { name, value } = e.target;
      setPromoCode((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
    
    const removeCoupon = () => {
        localStorage.removeItem("COUPON_SESSION");
        const couponSession = localStorage.getItem("COUPON_SESSION");
        const parsedCouponSession = couponSession ? JSON.parse(couponSession) : {
            discount_amount: 0.0,
            promo_id: 0,
            promo_code: "",
        };
        contextValues.setCouponSession(parsedCouponSession)
        window.location.reload();
    }

    const applyCouponTyping = () => {
        if (promoCode.promo_code == '') {
          toast.error("Please enter Coupon Code");
          return false;
        }
        const productData = {
          promo_code: promoCode.promo_code,
          cart_total: contextValues.cartSummary && contextValues.cartSummary.sellingTotal ? contextValues.cartSummary.sellingTotal : 0,
        };
        setSpinnerLoading(true)
        ApiService.postData("select-coupon", productData).then((res) => {
          if (res.data.status === 'success') {
            toast.success(res.data.notification)
            localStorage.removeItem("COUPON_SESSION");
            const couponSessionObj = {
              discount_amount: res.data.discount_amount,
              promo_id: res.data.promo_id,
              promo_code: res.data.promo_code,
            };

            localStorage.setItem("COUPON_SESSION", JSON.stringify(couponSessionObj));

            contextValues.setCartSummary(cartSummary[3]);

            contextValues.setCouponSession(couponSessionObj);

            setSpinnerLoading(false)
            // setTimeout(() => {
            //   contextValues.setToggleCouponModal(!contextValues.toggleCouponModal)
            // }, 500);
          } else {
            toast.error(res.data.notification)
            setSpinnerLoading(false)
          }
        }).catch((error) => {
          toast.error(error)
          setSpinnerLoading(false)
        });
      }

    return (
        <>
            <CartHeader />
            <BrowserView>
                {contextValues && contextValues.cartSessionData && contextValues.cartSessionData.length > 0 ?
                    <div className="cartSection">
                        <div className="container">
                            <div className="left">
                                <div className="p-30">
                                    {contextValues.userToken ?
                                        <div className="row g-3 mb-30">
                                            <div className="col-lg-12">
                                                <div className="cartSectionTitle">
                                                    <h5 className="mb-0 tx-18">Account</h5>
                                                    <div><a href="javascript:void(0);" className="tx-primary" onClick={(e) => logoutUser()}>Log Out</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <p className="mb-0 tx-13">{rowUserData.user_fname}</p>
                                                <p className="mb-0 tx-13">{rowUserData.user_email}</p>
                                            </div>
                                        </div>
                                        :
                                        <div className="row g-3 mb-30">
                                            <div className="col-lg-12">
                                                <div className="cartSectionTitle">
                                                    <h5 className="mb-0 tx-18">Customer information</h5>
                                                    <div>
                                                        Have an account? <a href="javascript:void(0);" className="tx-primary" onClick={(e) => showLogin()}>Login</a>
                                                    </div>
                                                </div>
                                            </div>
                                            {hideShowLogin ?
                                                <>
                                                    <div className="col-lg-6">
                                                        <div className="flotting-group mb-10">
                                                            <label className={emailFocused || userLoginDetails.user_email ? "label" : ""}>Email Address</label>
                                                            <input 
                                                                type="text" 
                                                                className="form-control loginRequired" 
                                                                placeholder="Email Address" 
                                                                name="user_email" 
                                                                value={userLoginDetails.user_email} 
                                                                onChange={(e) => onTodoChange(e)} 
                                                                onFocus={(e) => setEmailFocused(true)} 
                                                                onBlur={(e) => setEmailFocused(false)}></input>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="flotting-group">
                                                            <label className={passwordFocused || userLoginDetails.user_password ? "label" : ""}>Password</label>
                                                            <input 
                                                            type="password" 
                                                            className="form-control loginRequired" 
                                                            placeholder="Password" 
                                                            name="user_password" 
                                                            value={userLoginDetails.user_password} 
                                                            onChange={(e) => onTodoChange(e)}
                                                            onFocus={(e) => setPasswordFocused(true)} 
                                                            onBlur={(e) => setPasswordFocused(false)}></input>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <button class="btn btn-primary btn-block btn-large" type="button" onClick={(e) => userLoginProcess()}>{loginSpinnerLoading ? <Loader type="spinner-cub" bgColor={'#212529'} color={'#212529'} size={20} /> : <> Login</>}</button>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="col-lg-12">
                                                        <div className="flotting-group mb-10">
                                                            <label className="label">Email Address</label>
                                                            <input type="text" className="form-control" placeholder="Email Address"></input>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    }
                                    {contextValues.userToken ?
                                        <>
                                        <div className="checkoo mb-15">
                                            <div className="checkooHeader">
                                                <div className="checkooHeaderIcon" >
                                                    <i className="ri-map-pin-line ri-lg mr-10"></i>
                                                    <span>Delivery Address</span>
                                                </div>
                                            </div>
                                            <div className="addressselbox">
                                                <button type="button" className="addnewaddress" onClick={(e) => toggleCheckoutAddressModal()}><i className="ri-add-circle-line ri-lg mr-5"></i>Add New Address</button>
                                                {contextValues.userAddressList.length > 0 && contextValues.userAddressList.map((value, index) => (
                                                    <div className={`selectaddress ${value.ua_default_address == 1 ? 'active' : ''} mt-10`} key={index} 
                                                     onClick={(e) => selectAddress(value)}
                                                    >
                                                        <div className="saddresslable">{value.ua_address_type == "Other" ? value.ua_address_type_other : value.ua_address_type}</div>
                                                        <h6 className="mb-0 tx-14">{value.ua_name}</h6>
                                                        <p className="mb-1 tx-13">{value.ua_house_no}, {value.ua_city_name}, {value.ua_state_name}, {value.ua_pincode}</p>
                                                        <p className="tx-gray mb-0 tx-12">+91 {value.ua_mobile} . {value.ua_email}</p>
                                                        <span className="selectad"></span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
    
                                        </>
                                        :
                                        <div className="row g-3 mb-20">
                                            <div className="col-lg-12">
                                                <div className="cartSectionTitle">
                                                    <h5 className="mb-0 tx-18">Shipping Details</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="flotting-group mb-10">
                                                    <label className="label">First Name</label>
                                                    <input type="text" className="form-control" placeholder="First Name"></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="flotting-group mb-10">
                                                    <label className="label">Last Name</label>
                                                    <input type="text" className="form-control" placeholder="Last Name"></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="flotting-group mb-10">
                                                    <label className="label">Mobile Number</label>
                                                    <input type="text" className="form-control" placeholder="Mobile Number"></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="flotting-group mb-10">
                                                    <label className="label">Address</label>
                                                    <input type="text" className="form-control" placeholder="Address (Villa/Apt#, Building Name & Street)"></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="flotting-group mb-10">
                                                    <label className="label">Postal Code</label>
                                                    <input type="text" className="form-control" placeholder="Postal Code"></input>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="flotting-group mb-10">
                                                    <label className="label">Country</label>
                                                    <select className="form-select">

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="flotting-group mb-10">
                                                    <label className="label">State</label>
                                                    <select className="form-select">

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="flotting-group mb-10">
                                                    <label className="label">City</label>
                                                    <select className="form-select">

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mb-40">
                                        <div className="col-lg-12">
                                            <div className="flotting-group checkboxgroup">
                                                <input type="checkbox"></input>
                                                <span>My billing address is same as shipping address</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3 align-items-center">
                                        <div className="col-lg-4">
                                            <a href="/cart"><i class="ri-arrow-left-line mr-5"></i> Return to cart </a>
                                        </div>
                                        <div className="col-lg-8">
                                            <button class="btn btn-primary btn-block btn-large" type="button">Continue to Payment</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="p-30">
                                    {contextValues.cartSessionData.map((value, index) => {
                                        return (
                                            <div className="checkoutProduct" key={index}>
                                                <figure className="checkoutProductMedia">
                                                    <a href="#">
                                                        <img src={value.product_image}></img>
                                                    </a>
                                                </figure>
                                                <div className="checkoutProductContent">
                                                    <h2 className="title mb-1">{value.product_name}</h2>
                                                    <div className="cpcvar mb-10">
                                                        {/* <div><span className="tx-gray">Size:</span> </div> */}
                                                        <div><span className="tx-gray">Qty:</span> {value.quantity}</div>
                                                    </div>
                                                    <div className="price">
                                                        <ins class="new-price">{multiCurrency(value.product_selling_price)}</ins>
                                                        {value.product_discount > 0 && (<del class="old-price">{multiCurrency(value.product_price)}</del>)}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {/* Coupon */}
                                {contextValues.couponSession.promo_id > 0 ?
                                    <div className="checkoo mb-15">
                                        <div className="checkooHeader">
                                            <div className="checkooHeaderIcon">
                                                <i className="ri-price-tag-3-line ri-lg mr-10"></i>
                                                <div style={{ lineHeight: '20px' }}>
                                                    <h6 className="tx-11 mb-0">{contextValues.couponSession.promo_code} applied successfully</h6>
                                                    <span className="tx-12">You save <span className="tx-green">{contextValues.couponSession.discount_amount}</span> on this order!</span>
                                                </div>

                                            </div>
                                            <div className="itemscount" style={{ color: 'red' }} onClick={(e) => removeCoupon()}><i className="ri-delete-bin-5-line ri-lg"></i></div>
                                        </div>
                                    </div>
                                    :
                                    <div className="cartSectionCoupon mb-20">
                                        <input type="text" placeholder="Enter Coupon Code"
                                                        name="promo_code"
                                        value={promoCode.promo_code}
                                        onChange={(e) => onTodoChangePromo(e)}
                                        ></input>
                                        <button type="button" className="applybutton" onClick={(e) => applyCouponTyping()}>Apply</button>
                                    </div>
                                }

                                    
                                    <ul className="cartSectionPrice">
                                        <li>
                                            <span>Subtotal</span>
                                            <span>$1,800.00</span>
                                        </li>
                                        <li>
                                            <span>Shipping</span>
                                            <span>Enter shipping address</span>
                                        </li>
                                        <hr></hr>
                                        <li className="bigrow">
                                            <span>Total Amount</span>
                                            <span>$1,800.00</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
            </BrowserView>
            <MobileView>
            </MobileView>
            {contextValues.toggleCheckoutAddressModal && (<CheckoutAddressModal />)}

        </>
    );
}
export default CartAddress;
