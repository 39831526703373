import { BrowserView, MobileView } from "react-device-detect"
import CartHeader from "../../Components/CartHeader"

const CartCheckout = () => {
    return (<>
        <CartHeader />
        <BrowserView>
            <div className="cartSection">
                <div className="container">
                    <div className="left">
                        <div className="p-30">
                            <div className="row g-3 mb-30">
                                <div className="col-lg-12">
                                    <div className="cartSectionTitle">
                                        <h5 className="mb-0 tx-18">Customer information</h5>
                                        <div>
                                            Have an account? <a href="#" className="tx-primary">Login</a>
                                        </div>
                                    </div>
                                    
                                 </div>
                                 <div className="col-lg-12">
                                 <div className="flotting-group mb-10">
                                        <label className="label">Email Address</label>
                                        <input type="text" className="form-control" placeholder="Email Address"></input>
                                    </div>
                                    </div>
                                    <div className="col-lg-12">
                                    <div className="flotting-group">
                                        <label className="label">Password</label>
                                        <input type="text" className="form-control" placeholder="Password"></input>
                                    </div>
                                 </div>
                            </div>
                            <div className="row g-3 mb-20">
                                <div className="col-lg-12">
                                    <div className="cartSectionTitle">
                                        <h5 className="mb-0 tx-18">Shipping Details</h5>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                 <div className="flotting-group mb-10">
                                        <label className="label">First Name</label>
                                        <input type="text" className="form-control" placeholder="First Name"></input>
                                    </div>
                                    </div>
                                    <div className="col-lg-6">
                                    <div className="flotting-group mb-10">
                                        <label className="label">Last Name</label>
                                        <input type="text" className="form-control" placeholder="Last Name"></input>
                                    </div>
                                 </div>
                                 <div className="col-lg-12">
                                    <div className="flotting-group mb-10">
                                        <label className="label">Mobile Number</label>
                                        <input type="text" className="form-control" placeholder="Mobile Number"></input>
                                    </div>
                                 </div>
                                 <div className="col-lg-8">
                                    <div className="flotting-group mb-10">
                                        <label className="label">Address</label>
                                        <input type="text" className="form-control" placeholder="Address (Villa/Apt#, Building Name & Street)"></input>
                                    </div>
                                 </div>
                                 <div className="col-lg-4">
                                    <div className="flotting-group mb-10">
                                        <label className="label">Postal Code</label>
                                        <input type="text" className="form-control" placeholder="Postal Code"></input>
                                    </div>
                                 </div>
                                 <div className="col-lg-4">
                                    <div className="flotting-group mb-10">
                                        <label className="label">Country</label>
                                        <select className="form-select">

                                        </select>
                                    </div>
                                 </div>
                                 <div className="col-lg-4">
                                    <div className="flotting-group mb-10">
                                        <label className="label">State</label>
                                        <select className="form-select">

                                        </select>
                                    </div>
                                 </div>
                                 <div className="col-lg-4">
                                    <div className="flotting-group mb-10">
                                        <label className="label">City</label>
                                        <select className="form-select">

                                        </select>
                                    </div>
                                 </div>
                            </div>
                            <div className="row mb-40">
                                <div className="col-lg-12">
                                <div className="flotting-group checkboxgroup">
                                    <input type="checkbox"></input>
                                    <span>My billing address is same as shipping address</span>
                                </div>
                                </div>
                            </div>
                            <div className="row g-3 align-items-center">
                                <div className="col-lg-4">
                                    <a href="#"><i class="ri-arrow-left-line mr-5"></i> Return to cart </a>
                                </div>
                                <div className="col-lg-8">
                                <button class="btn btn-primary btn-block btn-large" type="button">Continue to Payment</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="p-30">
                            <div className="checkoutProduct">
                                <figure className="checkoutProductMedia">
                                    <a href="#">
                                        <img src="https://admyracollections.au/csadmin/public/img/uploads/products/92531714659993.jpg"></img>
                                    </a>
                                </figure>
                                <div className="checkoutProductContent">
                                    <h2 className="title mb-1">Golden Blooms Floral Lehenga Set</h2>
                                    <div className="cpcvar mb-10">
                                        <div><span className="tx-gray">Size:</span> </div>
                                        <div><span className="tx-gray">Qty:</span> 1</div>
                                    </div>
                                    <div className="price">
                                        <ins class="new-price">$120.00</ins>
                                        <del class="old-price">$150.00</del>
                                    </div>
                                </div>
                            </div>
                            <div className="checkoutProduct">
                                <figure className="checkoutProductMedia">
                                    <a href="#">
                                        <img src="https://admyracollections.au/csadmin/public/img/uploads/products/92531714659993.jpg"></img>
                                    </a>
                                </figure>
                                <div className="checkoutProductContent">
                                    <h2 className="title mb-1">Golden Blooms Floral Lehenga Set</h2>
                                    <div className="cpcvar mb-10">
                                        <div><span className="tx-gray">Size:</span> </div>
                                        <div><span className="tx-gray">Qty:</span> 1</div>
                                    </div>
                                    <div className="price">
                                        <ins class="new-price">$120.00</ins>
                                        <del class="old-price">$150.00</del>
                                    </div>
                                </div>
                            </div>
                            <div className="cartSectionCoupon mb-20">
                                <input type="text" placeholder="Enter Coupon Code"></input>
                                <button type="button" className="applybutton">Apply</button>
                            </div>
                            <ul className="cartSectionPrice">
                                <li>
                                    <span>Subtotal</span>
                                    <span>$1,800.00</span>
                                </li>
                                <li>
                                    <span>Shipping</span>
                                    <span>Enter shipping address</span>
                                </li>
                                <hr></hr>
                                <li className="bigrow">
                                    <span>Total Amount</span>
                                    <span>$1,800.00</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </BrowserView>
        <MobileView>
        </MobileView>
    </>)
}


export default CartCheckout