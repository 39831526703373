import React, { useEffect, useState, useRef,useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useParams } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Productlistsidebar from "../../Components/Elements/product_list_sidebar";
import MobileHeader from "../../Components/Elements/mobile_header";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";
import { useNavigate } from "react-router-dom";
import sessionCartData from "../../Components/Elements/cart_session_data";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import VariationModal from "../../Components/Modals/variation_modal";
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastContainer, toast } from "react-toastify";
import QuickViewModal from "../../Components/Modals/quick_view_modal";
import StarRating from "../../Components/Elements/starrating";
import SortModal from "../../Components/Modals/sort_modal";
import FilterModal from "../../Components/Modals/filter_modal";
import Modal from "react-bootstrap/Modal";
import multiCurrency from "../../Components/Elements/multi_currrency";
import { Helmet } from "react-helmet";
import DataContext from "../../Components/Elements/eventContext";

function Category() {
  const didMountRef = useRef(true);
  const [categoryData, setCategoryData] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");
  const [pageData, setPageData] = useState("");
  const dataArray = sessionCartData();
  const [loading, setLoading] = useState();
  const [categoriesData, setCategoriesData] = useState({});
  const [resProductsData, setProductsData] = useState([]);
  const { type, slug } = useParams();
  const [categoriesImageUrl, setCategoriesImageUrl] = useState("");

  const [totalLength, setTotalLength] = useState("");
  const [sorting, setSorting] = useState("");
  const [CatName, setCatName] = useState("");
  const [catImage, setCatImage] = useState("");
  const [scroll, setScroll] = useState();
  const [cartCount, setCartCount] = useState("");
  const [AttributeData, setAttributeData] = useState([]);
  const [setSession, SetSession] = useState("");
  const [productData, setProductData] = useState(null);
  const [helmetData, setHelmetData] = useState("")
  const [showSort, setShowSort] = useState(false);
  const [sortingSession, setSortingSession] = useState("")
  const [filterData, setFilterData] = useState(JSON.parse(localStorage.getItem('FILTER_SESSION')))
  
  const handleShowSortModal = () => {
    setShowSort(true);
  };
  const handleChildSortModalData = (status) => {
    setShowSort(status);
  };

  const parsedCartSession = dataArray[1];
  const navigate = useNavigate();
  useEffect(() => {

    setLoading(true);
    // getProductList();

    const getPageData = {
      slug: "category",
    };
    ApiService.postData("page-content", getPageData).then((res) => {
      if (res.status == "success") {
        setPageData(res.data);
      } else {
      }
    });
    ApiService.fetchData("all-categories").then((res) => {
      if (res.status === "success") {
        setCategoryData(res.resCategory);
        setBaseUrl(res.category_image_path);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
    getfilterproducts();

    didMountRef.current = false;
  }, []);

  const getfilterproducts = (sort = null) => {
    if (sort != null) {
      localStorage.setItem('SORT_SESSION', sort);
    }
    let sortsession = ""
    if (localStorage.getItem('SORT_SESSION') && localStorage.getItem('SORT_SESSION') != null) {
      sortsession = localStorage.getItem('SORT_SESSION');
      setSortingSession(localStorage.getItem('SORT_SESSION'))
    }
    let pageNumber = Math.ceil(resProductsData.length / 12) + 1;

    const dataString = {
      filterArray: JSON.parse(localStorage.getItem('FILTER_SESSION')),
      sort: sortsession ? sortsession : "",
      priceRange: JSON.parse(localStorage.getItem('RANGE_SESSION'))
    };
    // if(localStorage.getItem('FILTER_SESSION') !== null ){
    ApiService.filterPostData("getfilterproductsdata" + "?page=" + pageNumber, dataString,).then((res) => {
      if (res.status === "success") {
        if (sort != null) {
          setProductsData(res.data.data)
        }
        else {
          if (res.data.data.length > 0) {
            const apires = res.data.data;
            const mergeData = [...resProductsData, ...apires];
            setProductsData(mergeData);
            setTotalLength(res.data.total)


            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } else {
      }
    });
    // }
  };


  const [showFilter, setShowFilter] = useState(false);
  const contextValues = useContext(DataContext) 

  const handleFilteredData = (data) => {
    setProductsData(data);
  };
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [showVariation, setShowVariation] = useState(false);
  const handleShowVariation = (data) => {
    setProductData(data);
    setShowVariation(true);
  };
  const handleChildData = (status) => {
    setShow(status);
  };
  const [showQuick, setShowQuick] = useState(false);

  const handleShowQuickModal = (data) => {
    setProductData(data);
    setShowQuick(true);
  };
  const handleChildQuickModalData = (status) => {
    setShowQuick(status);
  };
  const handleSortingClick = (sortingValue) => {
    setSorting(sortingValue);
  };
  const handlecatName = (data) => {
    setCatName(data);
  };

  const handlecatImage = (categoryData) => {
    setCatImage(categoryData);
  };
  const setscrolldata = (scroll) => {
    setScroll(scroll);
  };

  const toggleSubcategory = (index) => {
    const subcategoryElement = document.querySelector(`.subcategory-${index}`);
    if (subcategoryElement) {
      subcategoryElement.style.display =
        subcategoryElement.style.display === "none" ? "block" : "none";
    }
  };

  const gotocategoryslug = (slug, subcategory) => {
    if (subcategory == 0) {
      navigate("/collection/category/" + slug);
    }
  };
  const addtofav = (productId) => {
    const dataString = {
      product_id: productId,
    };
    ApiService.postData("add-to-fav", dataString).then((res) => {
      if (res.data.status == "success") {
        var element = document.getElementById("wishlisticon" + productId);
        element.classList.remove("d-icon-heart", "d-icon-heart-full");
        element.classList.add(res.data.notification);
        if (res.data.notification === "d-icon-heart") {
          toast.success("Removed from wishlist");
        } else {
          toast.success("Added to Wishlist");
        }
      }
    });
  };


  const handleClosesort = () => {
    setShowSort(false)
  };

  const addtocart = (addproduct) => {
    //localStorage.clear();return ;
    let cartSession = localStorage.getItem("CART_SESSION");
    cartSession = cartSession ? JSON.parse(cartSession) : [];

    const product = {
      product_id: Number(addproduct.product_id),
      product_name: addproduct.product_name,
      product_image: addproduct.product_image
        ? addproduct.product_image
        : constant.DEFAULT_IMAGE,
      product_type: Number(addproduct.product_type),
      product_price: Number(addproduct.product_price),
      product_selling_price: Number(addproduct.product_selling_price),
      product_discount: addproduct.product_discount,
      product_variation: [],
    };
    const existingProductIndex = cartSession.findIndex((item) => {
      return (
        item.product_id === product.product_id &&
        JSON.stringify(item.product_variation) ===
        JSON.stringify(product.product_variation)
      );
    });
    if (addproduct.product_type === 0) {
      if (addproduct.product_inventory === 1) {
        if (Number(addproduct.product_stock) > 0) {
          // if (addproduct.product_backorder !== 0) {
          if (existingProductIndex !== -1) {
            if (
              cartSession[existingProductIndex].quantity + 1 <=
              Number(addproduct.product_stock)
            ) {
              if (
                Number(addproduct.product_moq) === 0 ||
                cartSession[existingProductIndex].quantity + 1 <=
                Number(addproduct.product_moq)
              ) {
                cartSession[existingProductIndex].quantity += 1;
                toast.success("Quantity updated Successfully");
              } else {
                toast.error(
                  "You can add maximum " +
                  addproduct.product_moq +
                  " quantity at a time!"
                );
                return false;
              }
            } else {
              toast.error("Product is out of stock");
              return false;
            }
          } else {
            cartSession.push({ ...product, quantity: 1 });
            toast.success("Product Added in cart Successfully");
          }
          /* } else {
            toast.error("Product is out of stock");
            return false;
          } */
        } else {
          if (addproduct.product_backorder === 0) {
            toast.error("Product is out of stock");
            return false;
          } else if (addproduct.product_backorder === 1) {
            if (existingProductIndex !== -1) {
              if (
                Number(addproduct.product_moq) === 0 ||
                cartSession[existingProductIndex].quantity + 1 <=
                Number(addproduct.product_moq)
              ) {
                cartSession[existingProductIndex].quantity += 1;
                toast.success("Quantity updated Successfully");
              } else {
                toast.error(
                  "You can add maximum " +
                  addproduct.product_moq +
                  " quantity at a time!"
                );
                return false;
              }
            } else {
              cartSession.push({ ...product, quantity: 1 });
              toast.success("Product Added in cart Successfully");
            }
          } else {
            cartSession.push({ ...product, quantity: 1 });
            toast.success("Product Added in cart Successfully");
          }
        }
      } else {
        if (existingProductIndex !== -1) {
          if (
            Number(addproduct.product_moq) === 0 ||
            cartSession[existingProductIndex].quantity + 1 <=
            Number(addproduct.product_moq)
          ) {
            cartSession[existingProductIndex].quantity += 1;
            toast.success("Quantity updated Successfully");
          } else {
            toast.error(
              "You can add maximum " +
              addproduct.product_moq +
              " quantity at a time!"
            );
            return false;
          }
        } else {
          if (
            Number(addproduct.product_moq) === 0 ||
            1 <= Number(addproduct.product_moq)
          ) {
            cartSession.push({ ...product, quantity: 1 });
            toast.success("Product Added in cart Successfully");
          } else {
            toast.error(
              "You can add maximum " +
              addproduct.product_moq +
              " quantity at a time!"
            );
            return false;
          }
        }
      }
    } else {
      if (existingProductIndex !== -1) {
        if (
          cartSession[existingProductIndex].quantity + 1 <=
          Number(addproduct.product_stock)
        ) {
          if (
            Number(addproduct.product_moq) === 0 ||
            cartSession[existingProductIndex].quantity + 1 <=
            Number(addproduct.product_moq)
          ) {
            cartSession[existingProductIndex].quantity += 1;
            toast.success("Quantity updated Successfully");
          } else {
            toast.error(
              "You can add maximum " +
              addproduct.product_moq +
              " quantity at a time!"
            );
            return false;
          }
        } else {
          toast.error("Product is out of stock");
          return false;
        }
      } else {
        if (1 <= Number(addproduct.product_stock)) {
          if (
            Number(addproduct.product_moq) === 0 ||
            1 <= Number(addproduct.product_moq)
          ) {
            cartSession.push({ ...product, quantity: 1 });
            toast.success("Product Added in cart Successfully");
          } else {
            toast.error(
              "You can add maximum " +
              addproduct.product_moq +
              " quantity at a time!"
            );
            return false;
          }
        } else {
          toast.error("Product is out of stock");
          return false;
        }
      }
    }

    localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
    const dataArray = sessionCartData();
    const parsedCartSession = dataArray[1];
    setCartCount(parsedCartSession.length)
    localStorage.removeItem("COUPON_SESSION");
    //setCartCount(cartSession.length)
  };
  const handleDataReceived = (data) => {
  };
  const removefilterdata = (key, value) => {
    const array = JSON.parse(localStorage.getItem('FILTER_SESSION'));
    const newArray = array[key].filter(item => item !== value);
    array[key] = newArray

    localStorage.setItem('FILTER_SESSION', JSON.stringify(array));
    window.location.reload()
  }

  const resetFilter = () => {
    localStorage.removeItem('collection')
    localStorage.removeItem('category')
    localStorage.removeItem('tag')
    localStorage.removeItem('FILTER_SESSION')
    localStorage.removeItem('SORT_SESSION')
    localStorage.removeItem('RANGE_SESSION')
    // window.location.reload()
  }
  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }
  return (
    <>
      <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta
          name="description"
          itemprop="description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "Admyra Collections"
          }
        />
        {pageData.page_meta_keyword != null ? (
          <meta name="keywords" content={pageData.page_meta_keyword} />
        ) : (
          ""
        )}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta
          property="og:image"
          content={constant.FRONT_URL + "img/logo.png"}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "Admyra Collections"
          }
        />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta
          name="twitter:description"
          content={
            pageData.page_meta_desc != null
              ? pageData.page_meta_desc
              : "Admyra Collections"
          }
        />
        <meta
          property="twitter:image"
          content={constant.FRONT_URL + "img/logo.png"}
        />
      </Helmet>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
      />
      <BrowserView>
        <Header state="inner-header"  />
        <main>
          <div className="page-content mt-5 mb-5">
            <Container>
              <Row>
                <Productlistsidebar
                  sorting={sorting}
                  slug={slug}
                  type={type}
                  onFilteredData={handleFilteredData}
                  categoryName={handlecatName}
                  categoryImage={handlecatImage}
                  setscroll={setscrolldata}
                  showmodal={showFilter}
                  onDataReceived={handleDataReceived}
                />
                <Col lg={9}>
                  {/* {categoriesData && (
                  <>
                    {loading === true ? (
                      <div className="cat-add-banner mb-3">
                        <Skeleton width="100%" height={200} />
                      </div>
                    ) : (
                      catImage && (
                        <div className="cat-add-banner mb-3">
                          <img
                            src={categoriesImageUrl + catImage}
                            alt={categoriesData.cat_name}
                            style={{ width: "100%" }}
                          />
                        </div>
                      )
                    )}
                  </>
                )} */}

                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      {(() => {
                        if (type == undefined && slug == undefined) {
                          return ('All Category');
                        } else if (CatName) {
                          return (CatName);
                        } else {
                          return (<Skeleton width={100} height={10} />);
                        }
                      })()}

                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <div className="page-title mb-2">
                    <h2 className="mb-0">

                      {(() => {
                        if (type == undefined && slug == undefined) {
                          return ('All Category');
                        } else if (CatName) {
                          return (CatName);
                        } else {
                          return (<Skeleton width={200} height={30} />);
                        }
                      })()}

                    </h2>
                  </div>
                  <div className="filterselect" style={{ marginBottom: "10px" }}><div className="filterselectlist"><ul>
                    {localStorage.getItem('FILTER_SESSION') != null ?
                      Object.keys(JSON.parse(localStorage.getItem('FILTER_SESSION'))).map((key) => (
                        JSON.parse(localStorage.getItem('FILTER_SESSION'))[key].map((value) => (
                          <li>
                            {value}
                            <i className="fa fa-times" aria-hidden="true" onClick={() => { removefilterdata(key, value) }}></i></li>)))) : ""}</ul></div>
                    {filterData && localStorage.getItem('FILTER_SESSION') != null && localStorage.getItem('FILTER_SESSION') != undefined ?
                      filterData.collection && filterData.collection.length > 0 || filterData.category && filterData.category.length > 0 ?
                        <button className="resetbutton" onClick={() => resetFilter}>Reset Filter</button> : '' : ''}</div>
                  <div className="shortby">
                    <div className="productcount">
                      Showing results of {totalLength} products.
                    </div>
                    <div className="shortbydrop">
                      <div className="shortbydrop-inner select">
                        <label>Sort by</label>
                        <select
                          onChange={(e) => getfilterproducts(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="featured" selected={sortingSession == "featured" ? true : ""}>Featured</option>
                          <option value="bestselling" selected={sortingSession == "bestselling" ? true : ""}>Best selling</option>
                          <option value="alphaasc" selected={sortingSession == "alphaasc" ? true : ""}>Alphabetically, A-Z</option>
                          <option value="alphadesc" selected={sortingSession == "alphadesc" ? true : ""}>Alphabetically, Z-A</option>
                          <option
                            value="asc"

                            selected={sortingSession == "asc" ? true : ""}
                          >
                            Price, low to high
                          </option>
                          <option
                            value="desc"
                            selected={sortingSession == "desc" ? true : ""}
                          >
                            Price, high to low
                          </option>
                          <option value="dateasc" selected={sortingSession == "dateasc" ? true : ""}> Date, old to new</option>
                          <option value="datedesc" selected={sortingSession == "datedesc" ? true : ""}>Date, new to old</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <InfiniteScroll
                    dataLength={resProductsData.length}
                    next={getfilterproducts}
                    hasMore={true}
                    endMessage={<p>No more data to load.</p>}
                    style={{ overflow: "hidden !important" }}
                  >
                    {loading === true ? (
                      <Row>
                        {[...Array(9)].map((_, index) => (
                          <Col lg={3}>
                            <div className="product" key={index}>
                              <figure className="product-media">
                                <Skeleton
                                  variant="text"
                                  width={280}
                                  height={315}
                                />
                              </figure>
                              <div className="product-details">
                                <h3 className="product-name">
                                  <Skeleton variant="text" width={150} />
                                </h3>
                                <div className="product-price">
                                  <Skeleton variant="text" width={150} />
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <>
                        {resProductsData.length > 0 ? (
                          <Row>
                            {resProductsData.map((value, indexProduct) => {
                              const productPrice = value.product_price;
                              const productSellingPrice =
                                value.product_selling_price;
                              const discount = (
                                ((productPrice - productSellingPrice) /
                                  productPrice) *
                                100
                              ).toFixed(0);
                              return (
                                <Col lg={3} key={indexProduct}>
                                  <div className="product mb-3">
                                    <figure className="product-media">
                                      <a
                                        href={
                                          "/product/" +
                                          value.product_slug
                                        }
                                      >
                                        {value.product_type === 0 ? (
                                          value.product_inventory === 1 ? (
                                            value.product_stock === 0 ? (
                                              value.product_backorder === 0 || value.product_backorder === 1 ? (
                                                <div className="stock-text-p mb-0"> Out of Stock</div>
                                              ) : (
                                                null
                                              )
                                            ) : (
                                              null
                                            )
                                          ) : (
                                            null
                                          )
                                        ) : (
                                          value.product_stock === 0 ? (
                                            <div className="stock-text-p mb-0"> Out of Stock</div>
                                          ) : (
                                            null
                                          )
                                        )}
                                        <img
                                          src={
                                            value.product_image != null
                                              ? value.product_image
                                              : constant.DEFAULT_IMAGE
                                          }
                                          alt={value.product_name}
                                          width="280"
                                          height="315"
                                        />
                                        {value.gallery.length > 0 ? (
                                          <img
                                            src={
                                              value.gallery[0].gallery_image
                                                ? value.gallery[0].gallery_image
                                                : constant.DEFAULT_IMAGE
                                            }
                                            alt={value.product_name}
                                            width="280"
                                            height="315"
                                          />
                                        ) : null}
                                      </a>
                                      {/* {value.product_tag_name != "" ? (
                                        <div className="product-label-group">
                                          {value.product_tag_name
                                            .split(", ")
                                            .map((tagvalue, indextag) => {
                                              return (
                                                <label
                                                  className="product-label label-new"
                                                  key={indextag}
                                                >
                                                  {tagvalue}
                                                </label>
                                              );
                                            })}
                                        </div>
                                      ) : null} */}

                                      <div className="product-action-vertical">
                                        {setSession ? (
                                          value.ufp_id > 0 ? (
                                            <a
                                              href="javascript:void(0)"
                                              className="btn-product-icon btn-wishlist"
                                              title="Add to wishlists"
                                              onClick={(e) =>
                                                addtofav(value.product_id)
                                              }
                                            >
                                              <i
                                                className="d-icon-heart-full"
                                                id={
                                                  "wishlisticon" +
                                                  value.product_id
                                                }
                                              ></i>
                                            </a>
                                          ) : (
                                            <a
                                              href="javascript:void(0)"
                                              className="btn-product-icon btn-wishlist"
                                              title="Add to wishlist"
                                              onClick={(e) =>
                                                addtofav(value.product_id)
                                              }
                                            >
                                              <i
                                                className="d-icon-heart"
                                                id={
                                                  "wishlisticon" +
                                                  value.product_id
                                                }
                                              ></i>
                                            </a>
                                          )
                                        ) : (
                                          <a
                                            href="javascript:void(0)"
                                            className="btn-product-icon btn-wishlist"
                                            title="Add to wishlist"
                                            onClick={loginModal}
                                          >
                                            <i className="d-icon-heart"></i>
                                          </a>
                                        )}
                                      </div>

                                      <div className="product-action">
                                        <a
                                          href="javascript:void(0)"
                                          className="btn-product btn-quickview"
                                          title="Quick View"
                                          onClick={() => {
                                            handleShowQuickModal(value);
                                          }}
                                        >
                                          Quick View
                                        </a>
                                      </div>
                                    </figure>
                                    <div className="product-details">
                                      <div className="product-details-inner">
                                        <h3 className="product-name">
                                          <a
                                            href={
                                              "/product/" +
                                              value.product_slug
                                            }
                                          >
                                            {value.product_name}
                                          </a>
                                        </h3>
                                        <div className="product-price">
                                          <ins className="new-price">
                                            {multiCurrency
                                              (value.product_selling_price)}
                                          </ins>
                                          {discount > 0 ? (
                                            <>
                                              <del className="old-price">
                                                {multiCurrency(value.product_price)}
                                              </del>
                                              <span className="off">
                                                {discount}% Off
                                              </span>
                                            </>
                                          ) : null}
                                        </div>
                                        {value.product && value.product.length > 0 && value.product_rating &&
                                          value.product_rating > 0 ? (
                                          <div className="ratings-container">
                                            <StarRating
                                              numberOfStars={value.product_rating}
                                            />
                                            <span>
                                              ( {value.product_review} reviews )
                                            </span>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {/* <div>
                                {value.product_type === 0 ?
                                <button
                                  className="btn btn-primary-outline btn-small"
                                  onClick={(e) => addtocart(value)}
                                >
                                  Add To Cart
                                </button>
                                :<button
                                className="btn btn-primary-outline btn-small"
                                onClick={() => {
                                  handleShowVariation(value);
                                }}
                              >
                                Add To Cart
                              </button>}
                              </div> */}
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        ) : (
                          <h2>No More Products found.</h2>
                        )}
                      </>
                    )}
                  </InfiniteScroll>
                </Col>
              </Row>
            </Container>
          </div>
        </main>
        <Footer />
      </BrowserView>

      <MobileView>
        <MobileHeader
          PageName="Category"
          
          
        />

        <main className="main">
          <div className="page-content">

            {loading === true ? (

              <div className="mcatbox-section">

                <div className="mcatbox">
                  <div className="mcatbox-inner">

                    <div className="mcatbox-media">
                      <Skeleton
                        variant="text"
                        width={50}
                        height={50}
                      />
                    </div>

                    <div
                      className="mcatbox-content">
                      <h6><Skeleton variant="text" width={120} /></h6>
                      <p><Skeleton variant="text" width={30} /></p>
                    </div>

                  </div>
                </div>

                <div className="mcatbox">
                  <div className="mcatbox-inner">

                    <div className="mcatbox-media">
                      <Skeleton
                        variant="text"
                        width={50}
                        height={50}
                      />
                    </div>

                    <div
                      className="mcatbox-content">
                      <h6><Skeleton variant="text" width={120} /></h6>
                      <p><Skeleton variant="text" width={30} /></p>
                    </div>

                  </div>
                </div>

                <div className="mcatbox">
                  <div className="mcatbox-inner">

                    <div className="mcatbox-media">
                      <Skeleton
                        variant="text"
                        width={50}
                        height={50}
                      />
                    </div>

                    <div
                      className="mcatbox-content">
                      <h6><Skeleton variant="text" width={120} /></h6>
                      <p><Skeleton variant="text" width={30} /></p>
                    </div>

                  </div>
                </div>

                <div className="mcatbox">
                  <div className="mcatbox-inner">

                    <div className="mcatbox-media">
                      <Skeleton
                        variant="text"
                        width={50}
                        height={50}
                      />
                    </div>

                    <div
                      className="mcatbox-content">
                      <h6><Skeleton variant="text" width={120} /></h6>
                      <p><Skeleton variant="text" width={30} /></p>
                    </div>

                  </div>
                </div>

                <div className="mcatbox">
                  <div className="mcatbox-inner">

                    <div className="mcatbox-media">
                      <Skeleton
                        variant="text"
                        width={50}
                        height={50}
                      />
                    </div>

                    <div
                      className="mcatbox-content">
                      <h6><Skeleton variant="text" width={120} /></h6>
                      <p><Skeleton variant="text" width={30} /></p>
                    </div>

                  </div>
                </div>

                <div className="mcatbox">
                  <div className="mcatbox-inner">

                    <div className="mcatbox-media">
                      <Skeleton
                        variant="text"
                        width={50}
                        height={50}
                      />
                    </div>

                    <div
                      className="mcatbox-content">
                      <h6><Skeleton variant="text" width={120} /></h6>
                      <p><Skeleton variant="text" width={30} /></p>
                    </div>

                  </div>
                </div>

                <div className="mcatbox">
                  <div className="mcatbox-inner">

                    <div className="mcatbox-media">
                      <Skeleton
                        variant="text"
                        width={50}
                        height={50}
                      />
                    </div>

                    <div
                      className="mcatbox-content">
                      <h6><Skeleton variant="text" width={120} /></h6>
                      <p><Skeleton variant="text" width={30} /></p>
                    </div>

                  </div>
                </div>

                <div className="mcatbox">
                  <div className="mcatbox-inner">

                    <div className="mcatbox-media">
                      <Skeleton
                        variant="text"
                        width={50}
                        height={50}
                      />
                    </div>

                    <div
                      className="mcatbox-content">
                      <h6><Skeleton variant="text" width={120} /></h6>
                      <p><Skeleton variant="text" width={30} /></p>
                    </div>

                  </div>
                </div>

                <div className="mcatbox">
                  <div className="mcatbox-inner">

                    <div className="mcatbox-media">
                      <Skeleton
                        variant="text"
                        width={50}
                        height={50}
                      />
                    </div>

                    <div
                      className="mcatbox-content">
                      <h6><Skeleton variant="text" width={120} /></h6>
                      <p><Skeleton variant="text" width={30} /></p>
                    </div>

                  </div>
                </div>

              </div>
            ) : (

              <>
                {
                  categoryData.map((value, index) => (
                    <div className="mcatbox-section" key={index}>
                      <div className="mcatbox">
                        <div className="mcatbox-inner">
                          <div className="mcatbox-media" onClick={() => navigate("/collection/category/" + value.cat_slug)
                          }
                          >
                            <img
                              src={
                                value.cat_image != null
                                  ? baseUrl + value.cat_image
                                  : constant.DEFAULT_IMAGE
                              }
                            />
                          </div>
                          <div
                            className="mcatbox-content"
                            onClick={() => {
                              resetFilter();
                              navigate("/collection/category/" + value.cat_slug)
                            }}
                          >
                            <h6>{value.cat_name}</h6>
                            {value.cat_desc != null ? <p>{value.cat_desc}</p> : ""}
                          </div>
                          <div className="mcatbox-arrow">
                            {value.children.length > 0 && (
                              <i
                                className="d-icon-angle-right"
                                onClick={() => toggleSubcategory(index)}
                              ></i>
                            )}
                          </div>
                        </div>
                      </div>
                      {value.children && value.children.length > 0 && (
                        <div
                          className={`mcatbox-list subcategory-${index}`}
                          style={{ display: "none" }}
                          key={index}
                        >
                          <ul>
                            {value.children.map((subvalue, subindex) => (
                              <a
                                href={"/collection/category/" + subvalue.cat_slug}
                                key={subindex}
                                onClick={() => resetFilter}
                              >
                                <li>
                                  {subvalue.cat_name}
                                  <i className="d-icon-angle-right"></i>
                                </li>
                              </a>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  ))}
              </>
            )}
          </div>
        </main>

      </MobileView>
  
      {showQuick && (
        <QuickViewModal
          showmodal={showQuick}
          productdata={productData}
          onChildData={handleChildQuickModalData}
        />
      )}
    </>
  );
}
export default Category;
